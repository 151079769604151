<template>
    <div>
        <v-card  :loading="loading">
            <v-card-title class="blue-grey lighten-5 text-color-title text-xs-subtitle-2 text-lg-h6 text-wrap">Configuración de permisos para asistentes</v-card-title>
            <v-card-text class="text-left">
                <v-switch v-for="p in permisos" :key="p.id"
                    hide-details
                    class="mx-2"
                    :label="p.descripcion"
                    v-model="p.valor"
                    @change="onStatusChange(p.id, p.valor)"
                ></v-switch>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="$emit('close');"
                >
                Cerrar
                </v-btn>
          </v-card-actions>
            </v-card>

    </div>
</template>
<script>
import UsuarioService from "@/services/usuario.service.js";
export default {
  data: () => ({
    loading: true,
    permisos:[]
  }),
  beforeMount() {
        UsuarioService.getPermisos().then((data)=>{
            if(data){
                this.permisos = data;
            }
        }).then(function () {
            this.loading = false;
        }.bind(this));
  },
  methods: {
    onStatusChange(id, valor) {
        UsuarioService.actualizarPermiso(id, valor).then((data)=>{
            if(data && data == true){
                this.$root.showMessage('El permiso ha sido actualizado', 'success');
            }
            else{

                this.$root.showMessage('El permiso no fue actualizado. Posibles errores: Error interno , ¿Tiene el rol permitido y suscripción vigente?', 'error');
            }
        });
    }
  },
};
</script>