import axios from "axios";
import { Urls } from "@/consts.js";

const UsuarioService = {
    getPermisos:function(){
        return axios.get(Urls.USUARIOS_URL +"/permisos")
    },
    actualizarPermiso:function(id, valor){
        return axios.post(Urls.USUARIOS_URL+"/permisos/"+id+"/"+valor);
    }
};

export default UsuarioService;